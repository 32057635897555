<template>
    <div>
        <div v-if="$root.appType('cash')">
            <div class='main-option-for-app'>
                <h1>Проверяем заявку...</h1>
                <p class="mb-6 mt-6 text-left waiting-status_subtitle">Время ожидания несколько минут</p>
            </div>

            <div class='preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/preloader-two.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="$root.appType('vtb')">
            <div class='main-option-for-app'>
                <h1 class="vtb_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'waiting-status_flexible_text' : changeText.flag}"
                    class="waiting-status_inline_text"
                    >

                    <p  v-show="changeText.flag === false"
                        class="text-left waiting-status_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="waiting-status_subtitle_vtb-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left waiting-status_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="waiting-status_subtitle_vtb-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/vtb/vtb_preloader-two.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="$root.appType('nordwind') || $root.appType('southwind') ">
            <div class='main-option-for-app'>
                <h1 class="nordwind_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'waiting-status_flexible_text' : changeText.flag}"
                    class="waiting-status_inline_text"
                    >
                    <p  v-show="changeText.flag === false"
                        class="text-left waiting-status_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="waiting-status_subtitle_nordwind-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="waiting-status_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left waiting-status_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="waiting-status_subtitle_nordwind-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader nordwind_preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/nordwind/nordwind_preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="$root.appType('travelata')">
            <div class='main-option-for-app'>
                <h1 class="travelata_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'waiting-status_flexible_text' : changeText.flag}"
                    class="waiting-status_inline_text"
                    >
                    <p  v-show="changeText.flag === false"
                        class="text-left travelata_waiting-status_subtitle">{{changeText.textForChange.subtitle[0]}}
                        <span class="travelata_waiting-status_subtitle-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="waiting-status_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left travelata_waiting-status_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="travelata_waiting-status_subtitle-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader travelata_preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/travelata/travelata-preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
        <div v-if="$root.appType('travelata')">
            <div class='main-option-for-app'>
                <h1 class="travelata_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'waiting-status_flexible_text' : changeText.flag}"
                    class="waiting-status_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left travelata_waiting-status_subtitle">{{changeText.textForChange.subtitle[0]}}
                        <span class="travelata_waiting-status_subtitle-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="waiting-status_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left travelata_waiting-status_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="travelata_waiting-status_subtitle-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader travelata_preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/travelata/travelata-preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
        <div v-if="$root.appType('bankiros')">
            <div class='main-option-for-app'>
                <h1 class="bankiros_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'waiting-status_flexible_text' : changeText.flag}"
                    class="waiting-status_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left bankiros_waiting-status_subtitle">{{changeText.textForChange.subtitle[0]}}
                        <span class="bankiros_waiting-status_subtitle-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="waiting-status_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left bankiros_waiting-status_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="bankiros_waiting-status_subtitle-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader bankiros_preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/bankiros/preloader-bankiros.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        activeForm: String,
        waitingData: {
            type: Object,
            default: () => ({})
        },
        merge: Boolean,
    },
    data: () => ({
        waitNewStatus: true,
        resultStatus: {},
        dictionaryStatus: {
            //заявка в процессе обработки (промежуточный статус)
            processing: {
                nextStep: {
                    status: false,
                    nextStepName: "",
                },
            },
            //необходимо прислать дополнительные данные клиента
            needMoreData: {
                nextStep: {
                    status: true,
                    nextStepName: "needMoreData",
                },
            },
            //заявка одобрена
            approved: {
                nextStep: {
                    status: true,
                    changeTemplate: true,
                    nextStepName: "accessApplication",
                },
            },
            //заявка одобрена, у клиента есть непогашенный займ по другой заявке
            merged: {
                nextStep: {
                    status: true,
                    changeTemplate: true,
                    nextStepName: "accessApplication",
                },
            },
            //заявка отклонена или клиент отказался от займа
            declined: {
                nextStep: {
                    status: true,
                    changeTemplate: true,
                    nextStepName: "declined",
                },
            },
            // заявка закрыта (конечный статус)
            closed: {
                nextStep: {
                    status: true,
                    changeTemplate: true,
                    nextStepName: "declined",
                },
            },
        },
        errorAuthTextApi: {
            status: false,
            text: "",
        },

        changeText: {
            //счетчик запроса status
            timePassedCounter: 0,
            // время после которого меняется текст 1 === 1 сек.
            timePassedLimit: 119,
            flag: false,
            textForChange: {
                title: ['Проверяем заявку', 'Заявка в обработке - скоро все будет готово!'],
                subtitle: ['Время ожидания несколько минут.', 'В случае необходимости с вами свяжется оператор для уточнения данных. Благодарим за терпение!'],
                // text: ['Пожалуйста, не закрывайте окно.']
            },
        },
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isMobile(){
            console.log('WaitingStatus', this.$vuetify.breakpoint.xs)
            return this.$vuetify.breakpoint.xs
        }
    },

    mounted() {
        if (this.waitingData && Object?.keys(this.waitingData)?.length) {
            this.sendDocuments(this.waitingData);
        } else {
            this.getNewStatus();
        }
    },

    methods: {
        openNextStep(data) {
            if (data.changeTemplate) {
                this.$emit("changeTemplate", data.nextStepName);
            } else {
                this.$emit("nextStep", data.nextStepName);
            }
        },
        getNewStatus() {
            this.errorAuthTextApi.status = false;
            this.resultStatus = setInterval(() => {
                this.changeText.timePassedCounter++
                if(this.changeText.timePassedCounter % 5 === 0){
                    this.connector.isStatusApplication()
                    .then((response) => {
                        console.log(response);

                        if (response.success) {
                            if(response.status === 'processing'){
                                console.log('changeText.timePassedCounter', this.changeText.timePassedCounter)
                                if(this.changeText.timePassedCounter >= this.changeText.timePassedLimit){
                                    this.changeText.flag = true
                                }
                            }
                            this.anketa.status = response.status;
                            for (const k in this.dictionaryStatus) {
                                if (k == response.status) {
                                    if (this.dictionaryStatus[k].nextStep.status) {
                                        console.log(`open new ${k}`);

                                        if (response.status == "needMoreData") {
                                            this.anketa.additionalData = response.info.required;
                                            this.resultStatus = clearInterval(this.resultStatus);
                                            return this.$emit("nextStep", "AdditionalInfo");
                                        } else if (response.status == "approved" || response.status == "merged") {
                                            console.log(`step ${response.status}`);
                                            this.anketa.creditInformation = response.info;
                                            for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                                                if (this.anketa.creditInformation.insuranceData[k].id === this.anketa.creditInformation.insuranceSelected) {
                                                    this.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                                                } else {
                                                    this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                                                }
                                            }

                                            this.changeText.timePassedCounter = 0
                                        }
                                        this.openNextStep(
                                            this.dictionaryStatus[k].nextStep
                                        );
                                        this.resultStatus = clearInterval(this.resultStatus);
                                    } else {
                                        console.log("again");
                                    }
                                }
                            }
                        } else {
                            this.resultStatus = clearInterval(this.resultStatus);
                            return false;
                        }
                    })
                    .catch((error) => {
                        this.resultStatus = clearInterval(this.resultStatus);
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = error;
                    });
                }

            }, 1000);
        },
        sendDocuments(data) {
            this.connector.saveDocuments(data)
              .then((response) => {
                if (response.success) {
                    this.getNewStatus();
                } else {
                  this.errorText = response.message;
                }
              })
              .catch((error) => {
                  if (error.message === "Network Error") {
                      this.errorText = "Произошла невообразимая ситуация!";
                  } else {
                      this.errorText = "Вы ввели неправильный пароль";
                  }
              })
        },

    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.loading-button {
    width: 100%;
    height: 60px;
    background: #b32f50 !important;
    border-radius: 12px;

    img {
        width: 25px;
        height: 25px;
    }
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg) matrix(0, 1, 1, 0, 0, 0);
    }

    100% {
        transform: rotate(360deg) matrix(0, 1, 1, 0, 0, 0);
    }
}

.waiting-status{
    &_subtitle {
        color: #656565 !important;
        display: inline;
        text-align: left;

        &_vtb{
            &-second {
                color: #1976D2;
                display: inline;
                text-align: left;
            }

        }
        &_nordwind{
            &-second {
                margin-top: 20px;
                color: #D6181E;
                display: inline;
                text-align: left;
            }

        }

    }

    &_inline_text {
        // display: inline;
        margin: 24px 0 !important;
        text-align: left !important;
    }

    &_flexible_text {
        display: flex;
        align-items: start;
        flex-direction: column;

    }

    // &_flex-column {
    //     flex-direction: column;
    // }
}

.text-left {
    align-items: start;
}

.travelata {
    &_waiting-status {
        &_subtitle {
            @include tr-text();
            font-family: 'OpenSansR';
            color: #707070;
            &-second {
                margin-top: 16px;
                color: #059BCE;
                display: inline;
                text-align: left;
            }

        }
    }
}

</style>
