<template>
    <div>
        <div class="main-option-for-app">
            <h1
                v-if="$root.appType('cash')"
                class="forms_title mb-8"
                >Авторизуйтесь или зарегистрируйтесь
            </h1>
<!--            <h1-->
<!--                v-if="$root.appType('vtb')"-->
<!--                class="vtb_form_title mb-8"-->
<!--                >Авторизуйтесь или зарегистрируйтесь-->
<!--            </h1>-->
<!--            <h1-->
<!--                v-if="$root.appType('travelata') && !isSizeMd"-->
<!--                class="travelata_form_title mb-8"-->
<!--                >Авторизуйтесь или зарегистрируйтесь-->
<!--            </h1>-->
<!--            <h1-->
<!--                v-if="$root.appType('travelata') && isSizeMd"-->
<!--                class="travelata_form_title mb-8"-->
<!--                >Регистрация клиента-->
<!--            </h1>-->
            <h1
                v-if="!$root.appType('cash') && !isSizeMd"
                :class="`${$root.state.type()}_forms_title`"
                class="mb-8"
                >Авторизуйтесь или зарегистрируйтесь
            </h1>
            <h1
                v-if="!$root.appType('cash') && isSizeMd"
                :class="`${$root.state.type()}_forms_title`"
                class="form_title mb-8"
                >Регистрация клиента
            </h1>
        </div>
        <v-form @submit.prevent ref="form">
            <!-- <FieldsCard v-if="!showRegisterCodeField || showFieldBeforeExist"  :anketaNames="['phone']" /> -->
            <FieldsCard :anketaNames="['phone']" />

            <!-- <div v-if="clientExists && isSmsForm" class="helper">
                <span class="helper-text" @click="changeTemplate(true)"></span>
                <span @click="callSopd" class="helper-text"
                    >Отправить код повторно
                </span>
            </div> -->

<!--            <v-btn-->
<!--                @click="goAuth"-->
<!--                :loading="loading"-->
<!--                :disabled="loading"-->
<!--                :class="!$root.appType('vtb') ? 'rectangle-button' : 'vtb-button'"-->
<!--                class="mt-6"-->
<!--                >-->
<!--                {{ !clientExists ? "Далее" : "Отправить" }}-->
<!--            </v-btn>-->
            <MainButton
                @click="goAuth"
                :loading="loading"
                :disabled="loading"
                >{{ !clientExists ? "Далее" : "Отправить" }}
            </MainButton>

        </v-form>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import MainButton from "@/components/global/MainButton";

export default {
    name: "Phone",
    components: { FieldsCard, MainButton },
    data: () => ({
        loading: false,
        clientExists: false,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isSizeMd(){
            return this.$vuetify.breakpoint.mdAndDown;
        }


    },
    methods: {
        goAuth() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                if (this.clientExists) {
                    this.auth();
                } else {
                    this.checkExists();
                }
            }
        },

        checkExists() {
            this.connector
                .clientExists(this.anketa.phone.cleanValue())
                .then((response) => {
                    if (response.success && response.clientExists) {
                        // this.isSmsForm = true
                        // return (this.clientExists = true);

                        const stepData = {
                            success: true,
                            prev: "phone",
                            next: "auth",
                            // clientExist: response.clientExist
                        };
                        // this.clientExist = false
                        this.$emit("changeTemplate", stepData);
                    } else if(response.success && !response.clientExists){
                        this.clientExists = false
                        const stepData = {
                            success: true,
                            prev: "phone",
                            next: "registration",
                        };
                        this.$emit("changeTemplate", stepData);
                    }
                    // else if (response.success && !response.clientExists) {
                    //     // this.showFieldBeforeExist = true
                    //     if(this.showFieldBeforeExist) {
                    //         if (!this.sopdIsSended) {
                    //             this.connector.sopdSendWithBirthday(this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.value)
                    //                 .then(response => {
                    //                     if(response.success === false) {
                    //                         this.errorAuthTextApi.status = true
                    //                         this.errorAuthTextApi.text = response.message
                    //                     }
                    //                 })

                    //             this.sopdIsSended = true
                    //             this.showRegisterCodeField = true
                    //             this.loading = false
                    //         } else {
                    //             this.connector.sopdVerify(this.anketa.registerCode.value, this.anketa.phone.valueFirstDigitIs9())
                    //                 .then(response => {
                    //                     this.errorAuthTextApi.status = false;
                    //                     if(response.success){
                    //                         localStorage.setItem("token", response.auth.token);
                    //                         localStorage.setItem("token_dt", response.auth.token_dt);
                    //                         this.$emit("changeTemplate", stepData);

                    //                     }
                    //                 })
                    //             }
                    //     } else {
                    //         this.$emit("changeTemplate", stepData)
                    //         this.showFieldBeforeExist = true
                    //     }

                    // }
                    // this.anketa.phone.disabled = true;
                    // this.anketa.registration = true;

                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    if (error.message === "Network Error") {
                        this.errorAuthTextApi.text =
                            "Произошла невообразимая ситуация!";
                    }
                    // else {
                    //     this.$emit("changeTemplate", stepData);
                    // }
                })
                .finally(() => (this.loading = false));
        },
    },

    mounted() {
        console.log('Phone current breakpoint', this.$vuetify.breakpoint);
    }
};
</script>

<style lang="scss" scoped>
    .phone {
        &_title {
            font-size: 16px !important;
        }
    }
</style>
