<style>
#app .v-text-field .v-input__append-outer {
  margin-top: 8px !important;
}
</style>

<template>
  <div>
    <div class="modal-screen-desc">
      <p
         :class="$root.appType('travelata') ? 'travelata_desc-tooltip' : 'desc-tooltip'"
          class="text-left"
        >
        {{ label }}
      </p>
    </div>

    <div class="modal-screen-input">
      <v-text-field
        v-mask="shortDate ? '##.####' : '##.##.####'"
        v-model="fieldValue"
        :placeholder="placeholder"
        :noplaceholder="shortDate ? '__.____' : '__.__.____'"
        solo
        flat
        :outlined="outlined"
        :readonly="menu"
        :rules="rules"
        :disabled="isDisabled"
        :errorMessages="errorMessages"
        type="tel"
        >
        <template #append>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="slide-x-reverse-transition"
            left
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <span class="hide-icon" v-bind="attrs" v-on="on">
                <v-icon large>mdi-calendar-range</v-icon>
              </span>
            </template>

            <v-date-picker
              v-if="shortDate"
              v-model="monthPickerValue"
              locale="ru-RU"
              color="primary"
              no-title
              type="month"
              @change="save"
            >
            </v-date-picker>

            <v-date-picker
              v-model="datePickerValue"
              locale="ru-RU"
              color="primary"
              no-title
              type="date"
              :active-picker.sync="activePicker"
              :max="max"
              :min="min"
              @change="save"
            >
            </v-date-picker>
          </v-menu>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
// Для того, чтобы мобильная клавиатура не выскакивала
// одновременно с datepicker, что вызывает его смещение.
// <v-text-field :readonly="menu" />

export default {
  props: {
    value: [Number, String, Boolean],
    label: String,
    append: {
      type: String,
      default: "D",
    },
    rules: Array,
    disabled: Boolean,
    placeholder: String,
    errorMessages: String,
    errorCount: Number,
    shortDate: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "1950-01-01",
    },
    max: {
      type: String,
      default: new Date().toISOString().substr(0, 10),
    },
    outlined: Boolean,
  },
  computed: {
		datePickerValue: {
			get() {
				return this.value;
			},
			set( val ) {
				if ( val != this.value ) {
					this.$emit('input', val);
				}
			}
		},

		monthPickerValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (val != this.value) {
          this.$emit("input", val);
        }
      },
    },
    appendText() {
      return this.append;
    },
    isDisabled() {
      return this.disabled;
    },
    // isShortDate() {
    //     return this.shortDate;
    // }
  },
  data: () => ({
    fieldValue: "",
    activePicker: null,
    //   date: null,
    menu: false,
  }),
  watch: {
		fieldValue( date ) {
			let day, month, year;

			if (this.shortDate) {
				if ( date.length == 7 ) {
					[month, year] = date.split('.');
					this.datePickerValue = `${year}-${month.padStart(2, '0')}`;
				}
			} else {
				if ( date.length == 10 ) {
					[day, month, year] = date.split('.');
					this.datePickerValue = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
				}
			}
		},

		datePickerValue(date) {
      if (!date || this.shortDate) return null;
      // console.log('date pick', date, this.shortDate );
      const [year, month, day] = date.split("-");
      this.fieldValue = `${day}.${month}.${year}`;
    },
    monthPickerValue(date) {
      if (!date || !this.shortDate) return null;
      // console.log('month pick', date, this.shortDate);
      const [year, month] = date.split("-");
      this.fieldValue = `${month}.${year}`;
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "DATE"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },


    // oninput(val) {
    //   if (val.length >= 10) {
    //
    //   }
    // },
  },
};
</script>
