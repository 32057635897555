
export default {
    data() {
        return {
            state: {
                type(){
                    let subdomain = window.location.hostname.split('.')[0]
                    const defaultType = 'cash';

                    let types = {
                        c: 'cash',
                        n: 'nordwind',
                        v: 'vtb',
                        b: 'bankiros',
                        t: 'travelata',
                        s: 'southwind'
                    }

                    if (subdomain === 'localhost') {
                        return types[this.typeOfDev()] || defaultType;
                    }

                    for (const typesKey in types) {
                        if(subdomain.includes(types[typesKey])) return types[typesKey];
                    }

                    return defaultType;
                },
                typeOfDev(){
                    let params = new URLSearchParams(window.location.search)
                    let typeOfDev = params.get('dev')
                    return typeOfDev
                },
                isNordwind() {
                    return this.type() === 'nordwind'
                },
                appType(type) {
                    console.log('stateeeeeeeeeeeeeeeeeeeeee', this.type())
                    return type === this.type()
                },
                addYandexMetricGoals(){
                    console.log('state type', this.type())
                    const counters = [
                        {
                            type: 'cash',
                            counter: '88149184'
                        },
                        {
                            type: 'nordwind',
                            counter: '96799237'
                        },
                        {
                            type: 'vtb',
                            counter: '97329470'
                        },
                        {
                            type: 'bankiros',
                            counter: '98069894'
                        },
                        {
                            type: 'travelata',
                            counter: '98070141'
                        },
                        {
                            type: 'southwind',
                            counter: '98072130'
                        }
                    ]
                    let currentCounter = null
                    for(let i = 0; i < counters.length; i++){
                        if(counters[i].type === this.type()){
                            currentCounter = counters[i].counter
                        }
                    }
                    // return this.$root[`metrika${currentCounter}`].reachGoal(goal);
                    return currentCounter
                }
            }
        }
    }
}
