<template>
    <div>
        <p
            v-if="!$root.appType('travelata')"
            :class="!$root.appType('vtb') ? 'registration_subtitle' : 'vtb_subtitle'"
            class="mb-3 text-left"
            >Заполните анкету, при необходимости прикрепите нужные документы
        </p>
        <v-form ref="form">
            <div v-for="field in fields" :key="field.title">
                <FormBlock :fields="field.items" :title="field.title" />
            </div>

            <v-fade-transition>
                <div>
<!--                    :class="[addClass('code-input'), $root.appType('travelata') ? 'travelata-orange-strong' : '']"-->
                    <div
                        v-if="openSmsCode"
                        :class="$root.state.type()"
                        class="d-flex align-center register-code">
                        <FieldsCard
                            :class="!$root.appType('cash') ? 'w200' : ''"
                            :anketaNames="['registerCode']"
                            :registerCodeInAuth="false"
                        />

                        <div
                            v-if="!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind')"
                            class="text-left ml-6 "
                            >
                            <h4 :class="addClass('block-title')">Код из СМС</h4>
                            <timer
                                v-if="!timerEnded"
                                label="Новый пароль можно получить через"
                                :seconds="seconds"
                                append="сек."
                                :class="$root.appType('travelata') ? 'travelata_sopd-timer' : 'sopd-timer'"
                                class="pl-0"
                                @complete="timerEnded = true"
                                :colorTimerValue="$vuetify.theme.defaults.light.primary"
                                :colorAppend="$vuetify.theme.defaults.light.primary"
                            />
                            <a
                                v-else
                                class="sopd-link pl-0"
                                @click.prevent="
                                smsSended = false;
                                timerEnded = false;
                                sendSms();
                                ">Отправить смс повторно
                            </a>
                        </div>
                        <div
                            v-if="$root.appType('travelata') || $root.appType('bankiros') || $root.appType('southwind')"
                            class="text-left ml-6 "
                            >
                            <h4
                                :class="addClass('block-title')"
                                >Код из СМС
                            </h4>
                            <timer
                                v-if="!timerEnded"
                                label="Отправили код на ваш номер. Отправить повторно  "
                                :seconds="seconds"
                                append="сек."
                                :class="$root.appType('travelata') ? 'travelata_sopd-timer' : 'sopd-timer'"
                                class="pl-0"
                                @complete="timerEnded = true"
                                :colorTimerValue="'#059BCE'"
                                :colorAppend="'#059BCE'"
                            />
                            <a
                                v-else
                                :class="$root.state.type()"
                                class="resend-code pl-0"
                                @click.prevent="
                                smsSended = false;
                                timerEnded = false;
                                sendSms();
                                "
                                >Отправить повторно
                            </a>
                        </div>

                    </div>
                    <p class="error-text travelata_main-registration_sms_error" v-show="errorText && ($root.appType('travelata') || $root.appType('bankiros'))">
                        {{ typeof errorText !== "object" ? errorText : errorText.reason}}
                    </p>
                </div>
            </v-fade-transition>
        </v-form>

<!--        <v-btn-->
<!--            @click="registration"-->
<!--            :class="$root.appType('vtb') ? 'vtb-button' : ' rectangle-button'"-->
<!--            class=" mt-6"-->
<!--            >-->
<!--            {{ smsSended ? "Далее" : "Отправить" }}-->
<!--        </v-btn>-->
        <MainButton @click="registration">{{ smsSended ? "Далее" : "Отправить" }}</MainButton>
        <p class="error-text" v-show="errorText && (!$root.appType('travelata') && !$root.appType('bankiros') )">
            Ошибка:
            {{ typeof errorText !== "object" ? errorText : errorText.reason }}
        </p>
    </div>
</template>

<script>
import FormBlock from "@/components/controls/FormBlock";
import FieldsCard from "@/components/FieldsCard";
import Timer from "@/components/controls/Timer";
import MainButton from "@/components/global/MainButton";

export default {
    components: {MainButton, Timer, FieldsCard, FormBlock },

    data: () => ({
        fields: [
            {
                title: "Основное",
                items: ["fio", "birthdate", "income"]
            },
            {
                title: "Паспорт РФ",
                items: ["passport", "issueDate", "divisionCode", "issuedBy"],
            },
            {
                title: "Контакты",
                items: ["residentialAddress", "registrationAddress", "phone"],
            },
        ],
        smsSended: false,
        openSmsCode: false,
        errorText: "",

        timerEnded: false,
        seconds: 120,
        exceptionsTypes: ['cash', 'nordwind']
    }),

    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        }
    },

    methods: {
        registration() {
            if (
                this.$refs.form.validate() &&
                !this.anketa.issueDate.errorMessages
            ) {
                if (this.smsSended && this.anketa.registerCode.success) {
                    this.createUser();
                } else {
                    this.sendSms();
                }
            }
        },

        createUser() {
            const registrationAddress = {
                block: this.anketa.registrationAddress.addressInfo.block || "",
                city: this.anketa.registrationAddress.addressInfo.city !== null ? this.anketa.registrationAddress.addressInfo.city : this.anketa.registrationAddress.addressInfo.settlement_with_type,
                flat: this.anketa.registrationAddress.addressInfo.flat || "",
                house: this.anketa.registrationAddress.addressInfo.house,
                index: this.anketa.registrationAddress.addressInfo.index || "",

                region: this.anketa.registrationAddress.addressInfo.region_type_full
                    ? this.anketa.registrationAddress.addressInfo.region + " " + this.anketa.registrationAddress.addressInfo.region_type_full
                    : this.anketa.registrationAddress.addressInfo.region,
                street: this.anketa.registrationAddress.addressInfo.street !== null ? this.anketa.registrationAddress.addressInfo.street : this.anketa.registrationAddress.addressInfo.settlement_with_type,
            };

            const residenceAddress = {
                block: this.anketa.residentialAddress.addressInfo.block || "",
                city: this.anketa.residentialAddress.addressInfo.city !== null ? this.anketa.residentialAddress.addressInfo.city : this.anketa.residentialAddress.addressInfo.settlement_with_type,
                flat: this.anketa.residentialAddress.addressInfo.flat || "",
                index: this.anketa.residentialAddress.addressInfo.index || "",
                house: this.anketa.residentialAddress.addressInfo.house,
                region: this.anketa.residentialAddress.addressInfo.region_type_full
                    ? this.anketa.residentialAddress.addressInfo.region + " " + this.anketa.residentialAddress.addressInfo.region_type_full
                    : this.anketa.residentialAddress.addressInfo.region,
                street: this.anketa.residentialAddress.addressInfo.street !== null ? this.anketa.residentialAddress.addressInfo.street : this.anketa.residentialAddress.addressInfo.settlement_with_type,
            };

            let personalData = {
                birthPlace: this.anketa.residentialAddress.addressInfo.city !== null ? this.anketa.residentialAddress.addressInfo.city : this.anketa.residentialAddress.addressInfo.region_with_type,
                dateOfBirth: this.anketa.birthdate.dateValue(),
                phoneNumber: this.anketa.phone.cleanValue(),
                document: {
                    issueDate: this.anketa.issueDate.dateValue(),
                    issuer: this.anketa.issuedBy.value,
                    issuerCode: this.anketa.divisionCode.value,
                    number: this.anketa.passport.cleanValue(),
                    type: 1,
                },
                firstName: this.anketa.fio.name,
                middleName: this.anketa.fio.middleName,
                lastName: this.anketa.fio.lastName,
                incomeAmount: {
                    // value: this.anketa.initialPayment.value + "",
                    value: this.anketa.income.cleanValue() + "",
                    currency: "RUB",
                },
                residenceAddress: residenceAddress,
                registrationAddress: this.anketa.residentialAddress.checkboxValue ? residenceAddress : registrationAddress,
            };

            const createData = {
                rate_id: this.anketa.conditions.rateId,
                period: this.anketa.term.valueTerm,
                percent: this.anketa.term.percent,
                amount: this.anketa.initialPayment.value,
                // account: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountNumberBank.cleanValue(),
                account: 40811111111111111111,
                // bik: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountBank.bik,
                bik: 111111111,
                // card_number: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? this.anketa.accountCard.cleanValue() : "",
                card_number: 1111111111111111,
                // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                bank_name: "Банк не найден. Проверьте правильность БИК.",
                // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,
                correspondent_account: 1111111111111111,
                merge: false,
                phone: this.anketa.phone.valueFirstDigitIs9(),
                token: localStorage.getItem('token'),
                token_dt: localStorage.getItem('token_dt'),
                personalData,
            };


            this.connector
                .isRegistrationAndCreateApplication(createData)
                .then((response) => {
                    if (response.success) {
                        this.anketa.client_id = response.new_client_id;
                        this.anketa.new_client_token = response.new_client_token;
                        this.anketa.token_timestamp = response.new_client_timestamp;
                        this.$emit("nextStep", "WaitingStatus");
                    } else {
                        this.errorText = response.message;
                    }
                })
                .catch((response) => {
                    this.errorText = response.message;
                });
        },

        sendSms() {
            if (!this.anketa.registerCode.success && !this.smsSended) {
                this.connector
                    .sopdSendWithBirthday(this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.dateValue())
                    .then((response) => {
                        console.log('sopdSend response', response)
                        if (response.success) {
                            this.openSmsCode = true;
                            this.smsSended = true;
                        } else {
                            this.errorText = response.message;
                        }
                    })
                    .catch((response) => {
                        this.errorText = response.message;
                    });
            } else {
                this.connector
                    .sopdVerify(
                        this.anketa.registerCode.value,
                        this.anketa.phone.valueFirstDigitIs9()
                    )
                    .then((data) => {
                        if (data.success) {
                            console.log(data)
                            localStorage.setItem('token', data.auth.token)
                            localStorage.setItem('token_dt', data.auth.token_dt)
                            this.anketa.registerCode.success = true;
                            this.registration();
                        } else {
                            this.anketa.registerCode.success = false;
                            this.errorText = data.message;
                        }
                    })
                    .catch((error) => {
                        this.anketa.registerCode.success = false;
                        this.errorText = error.message;
                    });
            }
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },

    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.registration_subtitle {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #656565;
}

.vtb {
    &_subtitle{
        @include vtb-form-text;
        color: #656565;

        @media (max-width: 400px) {
            color: #2F3441;
        }
    }

    &_block-title {
        @include vtb-form-title-paragraph;
        color:  #2F3441;
    }
}
.travelata {
    &_block-title {
        @include tr-text(20px, 600);
        font-family: 'OpenSansSB';
        color:  #333;
    }

    //&_subtitle{
    //    @include tr-text(12px, 400, 16px);
    //    color: #707070;
    //
    //    @media (max-width: 400px) {
    //        color: #2F3441;
    //    }
    //}

    //&_sopd-timer {
    //    @include tr-text(12px, 400, 16px);
    //    color: #707070;
    //    font-family: 'OpenSansR';
    //}

    &_main-registration_sms_error {
        text-align: left !important;
        margin-top: 10px;
        @include tr-text(12px, 400, 16px);
        font-family: 'OpenSansR';


    }
}
.register-code {
    &.travelata {
        ::v-deep .travelata_input {
            & .v-text-field__slot {
                & input {
                    @include tr-text(24px, 800, 28px);
                    font-family: 'OpenSansEB';
                    color: #EC933D !important;
                }

            }
        }
    }
    &.vtb {
        ::v-deep .vtb_input  {
            & .v-text-field__slot {
                & input {
                    @include tr-text(24px, 800, 28px);
                    font-family: 'OpenSansEB';
                    color: #1976D2 !important;
                }

            }
        }
    }

    &.southwind{
            ::v-deep .southwind_input {
                .v-text-field__slot {
                    & input {
                        @include tr-text(24px, 800, 28px);
                        font-family: 'OpenSansEB';
                        color: #D6181E !important;
                    }
                }

            }
    }
    &.nordwind{
        ::v-deep .nordwind_input {
            & .v-text-field__slot {
                & input {
                    @include tr-text(24px, 800, 28px);
                    font-family: 'OpenSansEB';
                    color: #D6181E !important;
                }

            }
        }
    }
}

.resend-code {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.4px;
    text-align: left;
    &.nordwind {
        color: #D6181E;
    }
    &.vtb {
        color: #1976D2;
    }
    &.travelata {
        color: #059BCE;
    }
    &.bankiros {
        color: #388EC3;
    }
    &.southwind {
        color: #D6181E;
    }

}
.w200 {
    max-width: 200px;
}
</style>
